<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("genoset-create.header") }}</h3>
      <GenosetForm :genoset="genoset" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import InitGenosetCreateInteractor from "@/business/genoset-create/init-genoset-create";
import SubmitFormInteractor from "@/business/genoset-create/submit-form";
import GenosetCreateScreenController from "@/adapters/controllers/screen-genoset-create";
import GenosetForm from "@/application/components/dna/GenosetForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "genoset-create",
  components: { GenosetForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initGenosetCreate: null,
        submitForm: null
      },
      data: null,
      isLoading: false,
      genoset: {
        name: "",
        aboutPtBrText: "",
        aboutEnUsText: "",
        aboutEsEsText: "",
        criteriaText: ""
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenosetCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initGenosetCreate = this.$injector.get(
      InitGenosetCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initGenosetCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.genoset);
    }
  }
};
</script>

<style lang="scss"></style>
